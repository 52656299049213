import { UserState } from "..";
import { createSlice } from "@reduxjs/toolkit";
import UserProfile from "../../app/data/user/UserProfile";

export const initialState: UserState = {
  loggedIn: false,
  loginProcess: false,
  logoutProcess: false,
  loginFailed: false,
  loginFirstAttemptFinished: false,
  profile: null,
  failClass: null,
  failReason: null,
  failUsername: undefined,
  getCurrentUser_is_Started: false,
  getCurrentUser_is_Succeed: false,
  getCurrentUser_is_Failed: false,
  showMobileSideMenu: false,
  status: undefined,
  impersonateTokenLoading: false,
  subAccounts: []
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    resetUser: (state) => {
      state = { ...initialState };
      state.loginFirstAttemptFinished = true;
    },
    loginStarted: (state) => {
      state.loginProcess = true;
      state.loginFailed = false;
    },
    loginSucceed: (state) => {
      state.loggedIn = true;
      state.loginProcess = false;
      state.loginFailed = false;
      state.loginFirstAttemptFinished = true;
    },
    loginFailed: (state) => {
      state.loginFailed = true;
      state.loginProcess = false;
      state.loginFirstAttemptFinished = true;
    },
    logoutStarted: (state) => {
      state.logoutProcess = true;
    },
    logoutSucceed: (state) => {
      state.loggedIn = false;
      state.logoutProcess = false;
      state.profile = null;
    },
    logoutFailed: (state) => {
      state.logoutProcess = false;
    },
    getCurrentUser_has_Started: (state) => {
      state.getCurrentUser_is_Started = true;
      state.getCurrentUser_is_Succeed = false;
      state.getCurrentUser_is_Failed = false;
    },
    getCurrentUser_has_Succeed: (state, { payload }) => {
      state.getCurrentUser_is_Succeed = true;
      state.getCurrentUser_is_Started = false;
      state.getCurrentUser_is_Failed = false;
      const userProfile = payload as UserProfile;
      state.profile = userProfile;
      state.loginFirstAttemptFinished = true;
    },
    getCurrentUser_has_Failed: (state) => {
      state.getCurrentUser_is_Failed = true;
      state.getCurrentUser_is_Started = false;
      state.getCurrentUser_is_Succeed = false;
      state.loginFirstAttemptFinished = true;
    },
    setFailReason: (state, { payload }) => {
      state.failClass = payload?.class || null;
      state.failReason = payload?.message || null;
      state.failUsername = payload?.username || null;
    },
    resetFailReason: (state) => {
      state.loginFailed = false;
      state.failClass = null;
      state.failReason = null;
      state.failUsername = null;
    },
    openMobileSideMenu: (state) => {
      state.showMobileSideMenu = true;
    },
    closeMobileSideMenu: (state) => {
      state.showMobileSideMenu = false;
    },
    getImpersonateTokenStarted: (state) => {
      state.impersonateTokenLoading = true;
    },
    getImpersonateTokenSucceed: (state) => {
      state.impersonateTokenLoading = false;
    }
  }
});

export const {
  resetUser,
  loginStarted,
  loginSucceed,
  loginFailed,
  logoutStarted,
  logoutSucceed,
  logoutFailed,
  setFailReason,
  resetFailReason,
  getCurrentUser_has_Started,
  getCurrentUser_has_Succeed,
  getCurrentUser_has_Failed,
  openMobileSideMenu,
  closeMobileSideMenu
} = userSlice.actions;

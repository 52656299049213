import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../hooks/storeHooks";
import DropdownMenu, { DropdownMenuItem } from "../../../components/dropdown-menu/dropdownMenu";
import { userSelector } from "../../../slices/user/userSlice";
import { closeMobileSideMenu } from "../../../slices/user/userCreateSlice";
import { logout } from "../../../slices/user/userSlice";
import { UserState } from "../../../slices";
import XGSIcon from "../../../components/icon/xgsIcon";
import XGSIcons from "../../../components/icon/xgsIcons";
import { ReactComponent as ViewIcon } from "../../../images/view.svg";
import "./userMenu.scss";
import { useNavigate } from "react-router-dom";
import { xgsRoutes } from "../../../app/route/RoutesConfig";

const UserMenu: React.FC<{}> = (props) => {
  const dispatch = useAppDispatch();
  const userState: UserState = useSelector(userSelector);
  const navigate = useNavigate();
  const [opened, setOpened] = useState<boolean>(false);

  const menuItems: DropdownMenuItem[] = [
    {
      text: "Log out",
      onClick: () => {
        dispatch(closeMobileSideMenu());
        if (userState.profile?.impersonated) {
          localStorage.removeItem("xgs-agent-impersonate-token");
          navigate(xgsRoutes.home);
          return window.location.reload();
        }
        setTimeout(() => {
          dispatch(logout());
        }, 500);
      },
      icon: XGSIcons.faSignOutAlt
    }
  ];

  const openDropdown = () => {
    setOpened(true);
  };

  const closeDropdown = () => {
    setOpened(false);
  };

  return (
    <div>
      <div className="xgs-user-menu" onClick={openDropdown}>
        <div className="xgs-user-menu__user xgs-user-menu__user--normal">
          <div className="xgs-user-menu__user__name">
            <>
              {
                userState.profile?.impersonated && (
                  <span className="xgs-userpick">
                    <ViewIcon /> &nbsp;&nbsp;
                  </span>
                )
              }
            </>
            {userState.profile?.firstName} {userState.profile?.lastName}
          </div>
        </div>
        <div className="xgs-user-menu__user xgs-user-menu__user--short">
          {userState.profile?.firstName.slice(0, 1)}
        </div>
        <span className="xgs-user-menu__arrow">
          <XGSIcon icon={opened ? XGSIcons.faChevronUp : XGSIcons.faChevronDown} />
        </span>
      </div>
      <DropdownMenu
        menuItems={menuItems}
        opened={opened}
        onClose={closeDropdown}
      />
    </div>
  );
}

export default UserMenu;
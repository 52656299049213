import React, { useEffect } from "react";
import { useAppDispatch } from "../../hooks/storeHooks";
import { getImpersonateToken } from "../../slices/user/userSlice";
import PageLoading from "../page-loading/pageLoading";
import { useNavigate } from "react-router-dom";
import { xgsRoutes } from "../../app/route/RoutesConfig";

const getQueryParam = (name: string) => {
  // not using URLSearchParams because IE11 doesn't support it
  let results = new RegExp("[?&]" + name + "=([^&#]*)").exec(window.location.href);
  return results ? (decodeURI(results[1]) || 0) : null;
};

const ImpersonateCallbackReceiver: React.FC<{}> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const tokenId = getQueryParam("tokenId") as string;

    dispatch(getImpersonateToken(tokenId, (token) => {
      localStorage.setItem("xgs-agent-impersonate-token", token);
      navigate(xgsRoutes.home);
      window.location.reload();
    }, () => {
      navigate(xgsRoutes.home);
      window.location.reload();
    }));
  }, [dispatch, navigate]);

  return <PageLoading isLoading={true} />;
};

export default ImpersonateCallbackReceiver;
